import candidateTokensApi from '@api/candidateTokens'

const state = {
  candidateId: null,
  candidateFirstName: null,
  candidateSurname: null,
  organisationName: null,
  organisationLogo: null,
  organisationColour: null,
  organisationSpecialFeatures: [],
  jobId: null,
  candidateSittingToken: null,
  jobExamSlugs: null,
  token: null,
  attempts: [],
  examsStarted: [],

  validated: null
}

const mutations = {
  /**
   * Commits the candidateToken
   *
   * @param {Object} state
   * @param {Object} candidateToken
   */
  storeCandidateToken(state, candidateToken) {
    state.candidateId = candidateToken.candidateId
    state.candidateFirstName = candidateToken.candidateFirstName
    state.candidateSurname = candidateToken.candidateSurname
    state.organisationName = candidateToken.organisationName
    state.organisationLogo = candidateToken.organisationLogo
    state.organisationColour = candidateToken.organisationColour
    state.organisationCompletedAction = candidateToken.organisationCompletedAction
    state.organisationSpecialFeatures = candidateToken.organisationSpecialFeatures
    state.jobId = candidateToken.jobId
    state.candidateSittingToken = candidateToken.candidateSittingToken
    state.jobExamSlugs = candidateToken.jobExamSlugs
    state.token = candidateToken.token
    state.attempts = candidateToken.attempts
    state.examsStarted = candidateToken.examsStarted
  },

  /**
   * Commits validation
   *
   * @param {Object} state
   * @param {Boolean} validated
   */
  storeValidated(state, validated) {
    state.validated = validated
  },

  /**
   * @param {Object} state
   * @param {Array} organisationSpecialFeatures
   */
  storeOrganisationSpecialFeatures(state, organisationSpecialFeatures) {
    state.organisationSpecialFeatures = organisationSpecialFeatures
  },

  /**
   * Commits validation
   *
   * @param {Object} attempt
   */
  addAttempt(state, attempt) {
    state.attempts.push(attempt)
  },

  /**
   * Commits a new examSlug
   *
   * @param {Object} attempt
   */
  addToExamsStarted(state, examSlug) {
    if (state.examsStarted.includes(examSlug)) {
      // Avoid duplications
      return
    }
    state.examsStarted.push(examSlug)
  }
}

const getters = {
  /**
   * Returns true if an attempt has been to validate the token
   *
   * @param {Object} state
   * @return {string}
   */
  hasTokenValidationAttempt: state => {
    return state.validated !== null
  },

  /**
   * Returns all exams
   *
   * @param {Object} state
   * @return {array}
   */
  isTokenValid: state => {
    return state.validated
  },

  /**
   * Can we take the exam?
   *
   * @param {Object} state
   * @param {string} examSlug
   * @return {Boolean}
   */
  canStartExam: state => examSlug => {
    return !state.examsStarted.includes(examSlug)
  },

  /**
   * @return {string}
   */
  candidateName: state => {
    if (!state.candidateFirstName || !state.candidateSurname) {
      return
    }
    return `${state.candidateFirstName} ${state.candidateSurname}`
  },

  /**
   * @return {string}
   */
  candidateId: state => {
    if (!state.candidateId) {
      return
    }
    return state.candidateId
  },

  /**
   * @return {string}
   */
  token: state => {
    if (!state.token) {
      return
    }
    return state.token
  },

  /**
   * @return {string}
   */
  jobId: state => {
    if (!state.jobId) {
      return
    }
    return state.jobId
  },

  /**
   * @return {string}
   */
  candidateSittingToken: state => {
    if (!state.candidateSittingToken) {
      return
    }
    return state.candidateSittingToken
  },

  /**
   * @return {string}
   */
  organisationName: state => {
    if (!state.organisationName) {
      return
    }
    return state.organisationName
  },

  /**
   * @return {string}
   */
  organisationColour: state => {
    if (!state.organisationColour) {
      return '#FFFFFF'
    }
    return state.organisationColour
  },

  /**
   * @return {Array}
   */
  organisationSpecialFeatures: state => {
    if (!state.organisationSpecialFeatures) {
      return []
    }
    return state.organisationSpecialFeatures
  },

  /**
   * @return {string}
   */
  organisationLogo: state => {
    if (!state.organisationLogo) {
      return
    }

    const logo = state.organisationLogo

    let url = []
    url.push('https://res.cloudinary.com')
    url.push(logo.cloudName)
    url.push('image/upload')
    url.push('h_40,w_auto')
    url.push('v' + logo.version)
    url.push(logo.publicId + '.' + logo.format)

    return url.join('/')
  },

  /**
   * @return {Object}
   */
  organisationCompletedAction: state => {
    if (!state.organisationCompletedAction) {
      return
    }
    return state.organisationCompletedAction
  },

  /**
   * @return {array}
   */
  attempts: state => {
    if (!state.attempts) {
      return []
    }
    return state.attempts
  },

  /**
   * @return {array}
   */
  jobExamSlugs: state => {
    if (!state.jobExamSlugs) {
      return []
    }
    return state.jobExamSlugs
  }
}

const actions = {
  /**
   * Validate token
   *
   * @param {Object} context
   * @return {Promise}
   */
  validateToken({ commit }, { candidateId, jobId, sittingToken, token }) {
    return candidateTokensApi.validate(token, { candidateId, jobId, sittingToken })
      .then(candidateToken => {
        if (candidateToken.candidateId !== candidateId) {
          console.error('Candidate ID does not match')
          commit('storeValidated', false)
          return
        }
        if (jobId && candidateToken.jobId !== jobId) {
          console.error('Job ID does not match')
          commit('storeValidated', false)
          return
        }
        if (sittingToken && candidateToken.candidateSittingToken !== sittingToken) {
          console.error('Sitting token does not match')
          commit('storeValidated', false)
          return
        }
        commit('storeValidated', true)
        commit('storeCandidateToken', candidateToken)
      })
      .catch(error => {
        commit('storeValidated', false)
        throw error
        // @TODO @Teddy 2019-11-27 Make work
        // None of this works because Axios isn’t returning the response
        // if (error.response.statusCode === 404) {
        //   console.error('Cannot find token')
        //   commit('storeValidated', false)
        // } else if (error.response.statusCode === 400) {
        //   console.error('Cannot validate token')
        //   commit('storeValidated', false)
        // } else {
        //   throw error
        // }
      })
  },

  recordExamStarted({ state, commit }, examSlug) {
    return candidateTokensApi.recordExamStarted(state.token, { examSlug })
      .then(data => {
        commit('addToExamsStarted', examSlug)
      })
  }
}

export { state, mutations, getters, actions }
