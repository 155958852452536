import examsApi from '@api/exams'
import provisionalCandidatesApi from '@api/provisionalCandidates'

const state = {
  answers: [],
  speedCompleted: 0,
  extraTime: false
}

const answerCalculator = {
  /**
   * Likely answer calculator using the flat style, where each part is worth
   * one point, so the max score is 2
   *
   * @param {Object} submittedAnswer
   * @param {Object} question
   * @return {Number}
   */
  likely: (submittedAnswer, question) => {
    let score = 0

    const mostLikely = question.answers.find(answer => {
      return answer.mostLikely
    })
    const leastLikely = question.answers.find(answer => {
      return answer.leastLikely
    })
    const likely = submittedAnswer.answer.likely

    if (!mostLikely || !leastLikely) {
      return score
    }

    if (mostLikely.uuid === likely.most) {
      score = score + 1
    }

    if (leastLikely.uuid === likely.least) {
      score = score + 1
    }

    return score
  },

  /**
   * The classic Likely answer calculator, which produces a score based on
   * how wrong or right you were
   *
   * @param {Object} submittedAnswer
   * @param {Object} question
   * @return {Number}
   */
  tieredLikely: (submittedAnswer, question) => {
    let score = 0

    const mostLikely = question.answers.find(answer => {
      return answer.mostLikely
    })
    const leastLikely = question.answers.find(answer => {
      return answer.leastLikely
    })
    const likely = submittedAnswer.answer.likely

    if (!mostLikely || !leastLikely) {
      return score
    }

    if (mostLikely.uuid === likely.most) {
      score = score + 2
    } else if (
      likely.most &&
      likely.least &&
      mostLikely.uuid !== likely.least
    ) {
      score = score + 1
    }

    if (leastLikely.uuid === likely.least) {
      score = score + 2
    } else if (
      likely.least &&
      likely.most &&
      leastLikely.uuid !== likely.most
    ) {
      score = score + 1
    }

    return score
  },

  /**
   * Choice answer calculator
   *
   * @param {Object} submittedAnswer
   * @param {Object} question
   * @return {Boolean}
   */
  choice: (submittedAnswer, question) => {
    let correctAnswer = question.answers.find(answer => {
      return answer.correct
    })
    return correctAnswer.uuid === submittedAnswer.answer.uuid
  }
}

const getters = {
  /**
   * Total number of questions answered
   *
   * @param {Object} state.answers
   * @return {Number}
  */
  getTotalQuestionsAnswered: state => {
    return state.answers.length
  },

  /**
   * Get the submitted answer for the question
   *
   * @param {String} questionUuid
   * @return {Object}
  */
  getSubmittedAnswerForQuestion: state => questionUuid => {
    return state.answers.find(answer => answer.questionUuid === questionUuid)
  },

  /**
   * Get extra time
   *
   * @return {Object}
  */
  extraTime: state => {
    return state.extraTime
  },

  /**
   * Is the answer Correct?
   *
   * @param {Object} question
   * @return {Boolean} null if not answered
   */
  calculateAnswer: (state, getters) => question => {
    const submittedAnswer = getters.getSubmittedAnswerForQuestion(
      question.uuid
    )

    if (!submittedAnswer) {
      return null
    }

    return answerCalculator[question.type](submittedAnswer, question)
  },

  /**
   * Uuids of all questions answered correctly
   *
   * @param {String} examSlug
   * @return {Array}
   */
  correctAnswers: (state, getters, rootState, rootGetters) => examSlug => {
    const questions = rootGetters['exams/questions'](examSlug)
    const correctAnswers = questions.filter(question => {
      return getters.calculateAnswer(question)
    })

    return correctAnswers.map(q => q.uuid)
  },

  /**
   * Uuid of correct answer for a question
   *
   * @param {String} question
   * @return {String}
   */
  correctAnswerUuid: (state, getters, rootState, rootGetters) => question => {
    return question.answers.find(answer => answer.correct).uuid
  },

  /**
   * Uuid of most likely answer for a question
   *
   * @param {String} question
   * @return {String}
   */
  mostLikelyAnswerUuid: (
    state,
    getters,
    rootState,
    rootGetters
  ) => question => {
    return question.answers.find(answer => answer.most_likely).uuid
  },

  /**
   * Uuid of least likely answer for a question
   *
   * @param {String} question
   * @return {String}
   */
  leastLikelyAnswerUuid: (
    state,
    getters,
    rootState,
    rootGetters
  ) => question => {
    return question.answers.find(answer => answer.least_likely).uuid
  },

  /**
   * Total number of questions answered correctly
   *
   * @param {String} exam
   * @return {Number}
   */
  totalQuestionsCorrect: (state, getters, rootState, rootGetters) => exam => {
    const questions = rootGetters['exams/questions'](exam.slug)

    if (exam.instruction_slug === 'likely') {
      let totalScore = 0

      questions.forEach(question => {
        totalScore = totalScore + getters.calculateAnswer(question)
      })
      return totalScore
    }

    if (exam.instruction_slug === 'choice') {
      let correctAnswers = questions.filter(question => {
        return getters.calculateAnswer(question)
      })
      return correctAnswers.length
    }
  },

  /**
   * Total number of questions answered incorrectly
   *
   * @param {String} examSlug
   * @return {Number}
   */
  totalQuestionsInCorrect: (
    state,
    getters,
    rootState,
    rootGetters
  ) => examSlug => {
    const questions = rootGetters['exams/questions'](examSlug)
    const incorrectAnswers = questions.filter(question => {
      return !getters.calculateAnswer(question)
    })

    return incorrectAnswers.length
  },

  /**
   * Get calculated results stats
   *
   * @return {Object}
   */
  result: (state, getters, rootState, rootGetters) => exam => {
    let questionsTotal = 0
    let questionsCorrect
    let questionsIncorrect
    let scorePercentage
    let questionsAnswered
    let questions = rootGetters['exams/questions'](exam.slug)

    if (exam.instruction_slug === 'choice') {
      questionsCorrect = getters.totalQuestionsCorrect(exam)
      questionsIncorrect = getters.totalQuestionsInCorrect(exam.slug)
      questionsTotal = questions.length
      questionsAnswered = getters.getTotalQuestionsAnswered
      scorePercentage = Math.round((questionsCorrect / questionsTotal) * 100)
    }

    if (exam.instruction_slug === 'likely') {
      questionsCorrect = getters.totalQuestionsCorrect(exam)
      questions.forEach(question => {
        questionsTotal = questionsTotal + Math.ceil(question.answers.length / 2)
      })
      questionsAnswered = (getters.getTotalQuestionsAnswered * 2)
      scorePercentage = Math.round((questionsCorrect / questionsTotal) * 100)
      // Now replace the questionsTotal with the ‘normalised’ amount
      questionsTotal = (questions.length * 2)
      // And we know the answered questions, so we can find the incorrect
      questionsIncorrect = questionsAnswered - questionsCorrect
    }

    return {
      candidateId: rootGetters['candidates/candidateId'],
      jobId: rootGetters['candidates/jobId'],
      candidateSittingToken: rootGetters['candidates/candidateSittingToken'],
      examSlug: exam.slug,
      examId: exam.uuid,
      scorePercentage,
      speedCompleted: state.speedCompleted,
      questionsAnswered,
      questionsCorrect,
      questionsIncorrect,
      questionsTotal,
      answers: state.answers,
      extraTime: state.extraTime
    }
  }
}

const mutations = {
  handleAnswer(state, submittedAnswer) {
    let answerToBeReplaced = state.answers.find(answer => {
      return answer.questionUuid === submittedAnswer.questionUuid
    })
    let answerIndex = state.answers.indexOf(answerToBeReplaced)

    if (answerToBeReplaced) {
      state.answers.splice(answerIndex, 1)
    }

    state.answers.push(submittedAnswer)
  },

  setSpeedCompleted(state, speedCompleted) {
    state.speedCompleted = speedCompleted
  },

  clearAnswers(state) {
    state.answers = []
  },

  setExtraTime(state, extraTime) {
    state.extraTime = extraTime
  }
}

const actions = {
  setSpeedCompleted({ commit }, speedCompleted) {
    commit('setSpeedCompleted', speedCompleted)
  },

  handleAnswer({ commit }, submittedAnswer) {
    commit('handleAnswer', submittedAnswer)
  },

  /**
   * Submit exam answers to API
   *
   * @param {Object} context
   * @return {Promise}
   */
  endExam({ commit, getters, rootGetters }, { exam, jobUuid, antiCheatData, usedExtraTime }) {
    console.log('📒 Submitting exam answers')

    if (rootGetters['provisionalCandidate/token']) {
      return provisionalCandidatesApi.storeAttempt(
        rootGetters['provisionalCandidate/group'].slug,
        rootGetters['provisionalCandidate/token'],
        getters.result(exam)
      )
        .then(response => {
          console.log('😎 Delivered submitted exam result')
          commit('provisionalCandidate/addAttempt', response, { root: true })
        })
    }

    const endPoint = (result) => {
      if (jobUuid) {
        return examsApi.candidateWizardAttempt(
          {
            ...result,
            ...antiCheatData,
            jobId: jobUuid,
            usedExtraTime
          })
      }
      return examsApi.candidateAttempt(
        {
          ...result,
          ...antiCheatData,
          usedExtraTime
        })
    }

    return endPoint(getters.result(exam))
      .then(response => {
        console.log('😎 Delivered submitted exam answers')
        commit('candidates/addAttempt', response, { root: true })
      })
  }
}

export { state, getters, mutations, actions }
